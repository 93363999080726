import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as image from "../../resources/images";
import {
	getCapitalized,
	showRoundValue
} from "../../utils/helper";
import PieGraph from "./PieGraph";
import Sidebar from "./Sidebar";

import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
	ShimmerSimpleGallery
} from "react-shimmer-effects";
import { selectTab, showModal } from "../../redux/actions";
import {
	getDimensionPlanData,
	getJourny,
	getJournyCourses,
	getLearnerPlanTags,
	getSocialActivityDetail,
	getUpdateLearnerPlan,
} from "../../redux/actions/APIs";
import { showOverallModal } from "../../redux/actions/Home";
import { PATHS } from "../../utils";
import { holisticSkillData } from "../../utils/DataObjects";
import { useForm } from "../../utils/Packages";
import LessonCongratulationsPopup from "../dimensions/course/vickycourses/LessonCongratulationsPopup";
import LearnerPlanRightPanel from "../learnerplan/LearnerPlanRightPanel";
import CongratulationCard from "../widget/CongratulationCard";
import ShowOverallProgressPopup from "./ShowOverallProgressPopup";
import StudentDashBord from "./StudentDashBord";
import TeacherDashboard from "./TeacherDashboard";

const HolisticView = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const { defaultChildData, loggedInUser, getMyPlanData, showPanelObj } = useSelector((state) => state.collections);
	const { dashboardData, showoverModal } = useSelector((state) => state.home);

	const {
		getValues,
		setValue,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm({ mode: "onTouched" });


	const [message, setMessage] = useState();
	const [showOverallProgressPopup, setShowOverallProgressPopup] =
		useState(false);

	const handleShowOverallProgressPopup = (result) => {
		setShowOverallProgressPopup(result);
	};

	const [toggleState, setToggleState] = useState(true);
	const [weeks, setWeeks] = useState(0);
	const [selectedTab, setSelectedTab] = useState("");

	useEffect(() => {
		dispatch(selectTab());
		if (dashboardData?.userPlans?.length > 0) {
			setToggleState(true);
			setWeeks(parseInt(dashboardData?.week));
		} else {
			setToggleState(false);
		}
	}, [dashboardData]);

	let socialObj = [];
	let emotionalObj = [];
	let spiritualObj = [];
	let physicalObj = [];
	let intellectualObj = [];

	dashboardData &&
		dashboardData?.dimensions.map((obj) => {
			if (obj.name === "Physical") {
				physicalObj = obj;
			} else if (obj.name === "Mindfulness") {
				spiritualObj = obj;
			} else if (obj.name === "Social") {
				socialObj = obj;
			} else if (obj.name === "Emotional") {
				emotionalObj = obj;
			} else if (obj.name === "Intellectual") {
				intellectualObj = obj;
			}
		});

	const handleClickHere = () => {
		dispatch(getJourny(false));

		history.push({
			pathname: PATHS.COURSEPAGE_STR + defaultChildData?.id,
			state: { isFilter: true },
		});
	};

	const showOverall = () => {
		dispatch(showOverallModal(true));
	};

	let progressCount = 0;

	dashboardData &&
		dashboardData?.dimensions.map((vl) => {
			progressCount += vl.dimPro;
		});

	const handleToggleState = (state) => {
		setToggleState(state);
	};

	const _redirectLearnerPlan = () => {
		history.push(PATHS.STR_LEARNER_PLAN + defaultChildData?.id);
	};

	const handleMoreOptions = (type) => {
		if (type === "changePace") {
			history.push({
				pathname: PATHS.STR_LEARNER_PLAN + defaultChildData?.id,
				state: {
					show: "changePace",
					screen: 1,
				},
			});
		} else if (type === "createNewPlan") {
			dispatch(getUpdateLearnerPlan(defaultChildData?.id));
			history.push({
				pathname: PATHS.STR_LEARNER_PLAN + defaultChildData?.id,
				state: {
					show: "helpmefind",
					screen: 1,
				},
			});
			dispatch(getLearnerPlanTags());
			dispatch(getJourny());
			dispatch(getDimensionPlanData());
			dispatch(getJournyCourses());
		} else if (type === "courseCatlog") {
			history.push(PATHS.COURSEPAGE_STR + defaultChildData?.id);
		}
	};
	//Why A Plan add message 16 may 2024
	const handlePopUpOpen = (type) => {
		if (type === "whyplan") {
			dispatch(
				showModal({
					type: "learnerPlanInfo",
					title: "Why A Plan?",
					icon: image.Calendericon,
					message: `Creating a Plan helps parents and students work Bloomster into a regular habit and maintain consistency for maximum effectiveness.
`,
				})
			);
		}
	};

	const handleReports = (value, dimObj) => {
		history.push({
			pathname: PATHS.STUDENT_REPORTS,
			state: {
				type: value, dimObj: dimObj, usertype: "teacher"
			},
		});
	}

	const [showPlanCourseData, setShowPlanCourseData] = useState([]);
	const gotoSelectedPlan = (planIndex) => {
		let courses = [];
		getMyPlanData?.records[planIndex]?.dimensions?.map((dim, index) => {
			dim?.skills?.map((skill, key) => {
				skill?.courses?.map((course, cKey) => {
					course.skill = skill;
					course.dimension = { key: dim?.id, value: dim?.name }
					courses.push(course);
				})
			})
		})
		setShowPlanCourseData(courses);
	}

	useEffect(() => {
		if (getMyPlanData?.records?.length > 0) {
			gotoSelectedPlan(0);
		}
	}, [getMyPlanData])

	const [getTeaccherProf, setTeacherProf] = useState(false);

	useEffect(() => {
		if (loggedInUser?.role?.name === "TEACHER") {
			setTeacherProf(true);
		} else if (loggedInUser?.role?.name === "PROVIDER") {
			history.push(PATHS.COURSE_STR + loggedInUser?.id);
		}
	}, [loggedInUser])

	const [showCongratulationModel, setShowCongratulationModel] = useState(false);
	const [congratulationPopup, setCongratulationPopup] = useState(false);
	const [courseDetail, setCourseDetail] = useState([]);
	const [courseActivityId, setCourseActivityId] = useState([]);

	const handleCongratulationPopup = (vl) => {
		setCongratulationPopup(vl);
	};

	const getDataFromCourseProgress = (result, data, course) => {
		setCourseDetail(course);
		setCourseActivityId(data);
		dispatch(getSocialActivityDetail());
		if (course?.isAcademic) {
			setCongratulationPopup(result);
		} else {
			setShowCongratulationModel(result);
		}
	};

	return (
		<>
			{loggedInUser?.role?.name === "TEACHER" && getTeaccherProf ? (
				<TeacherDashboard handleReports={handleReports} />
			) : loggedInUser?.role?.name === "STUDENT" ? (
				<StudentDashBord />
			) : (
				<div className='LeftbarPannel p-0' id=''>
					<div className='form-title mt-0 mb-0 Enrollcoursetitle heading'>
						<h2
							data-toggle='collapse'
							className='m-0 pt-0 pb-1 w-100 flex justify-content-between '>
							<span className="flexone">
								{" "}
								<img src={image.reports_docs} className='mr-2 report_Iocns' alt='' />
								{loggedInUser?.role?.name !== "TEACHER" ? "Holistic View" : "Reports"}
							</span>{" "}
							{
								loggedInUser?.role?.name !== "TEACHER" && (
									<span>
										<img
											src={image.chat_icon}
											className='chat_icon pointer'
											alt=''
											onClick={() => showOverall()}
										/>
									</span>
								)
							}
						</h2>
					</div>
					<div className='CourseCardWrapper fullHeight100'>

						{dashboardData ? (
							<div className='NhPageWrap'>
								{/* {
								dashboardData?.userPlans?.length === 0 && (
									<h4 className="stripLeaner_plan">
										<a href="javascript:void(0)" onClick={() => _redirectLearnerPlan()}>Create learner plan for {getCapitalized(defaultChildData.firstName)}</a>
									</h4>
								)
							} */}

								{
									["TEACHER", "STUDENT"]?.includes(loggedInUser?.role?.name) ? (
										<h3 className='text-left mt-4 statementabthome'>
											Here is {dashboardData?.sessionName}'s overall dimensional progress. Click on the dimension bubbles to filter the progress by skill and course.
										</h3>
									) : (
										<h3 className='text-left mt-4 statementabthome'>
											{dashboardData?.progress <= 0 && (
												<>
													{/* Explore our most popular courses on your right or{" "}
										<a href="#" onClick={() => handleClickHere()}>
											click here
										</a>{" "}
										to explore other courses to begin{" "}
										{defaultChildData &&
											getCapitalized(defaultChildData.firstName)}
										's holistic growth journey. */}
													Here is{" "}
													{defaultChildData &&
														getCapitalized(defaultChildData.firstName)}
													's overall and dimensional progress. Click on the dimension
													bubbles to explore a dimension.
												</>
											)}
											{dashboardData?.progress > 0 &&
												dashboardData?.progress <= 15 && (
													<>
														{defaultChildData &&
															getCapitalized(defaultChildData.firstName)}{" "}
														has taken their first steps towards their holistic growth.
														They can accelerate their progress by countinuing their
														current course or enrolling in other courses by{" "}
														<a href='#' onClick={() => handleClickHere()}>
															clicking here
														</a>
														.
													</>
												)}
											{dashboardData?.progress > 15 &&
												dashboardData?.progress <= 50 && (
													<>
														{defaultChildData &&
															getCapitalized(defaultChildData.firstName)}{" "}
														is making good progress in their holistic growth journey.
														Click on each dimension circle to learn more about their
														progress or{" "}
														<a href='#' onClick={() => handleClickHere()}>
															click here
														</a>{" "}
														to explore more courses.
													</>
												)}
											{dashboardData?.progress > 50 && (
												<>
													{defaultChildData &&
														getCapitalized(defaultChildData.firstName)}{" "}
													is well on their way to becoming a well-rounded individual.
													Click on each dimension circle to learn more about their
													progress and decide what to work on next.{" "}
												</>
											)}
										</h3>
									)
								}
								{/* <h3 className="text-center mt-4 statementabthome" id="statementabthome" dangerouslySetInnerHTML={{ __html: message }}></h3> */}
								<div className={`NHProgreschartList flex flex-wrap ${loggedInUser?.role?.name === "TEACHER" || loggedInUser?.role?.name === "STUDENT" ? "schoolbubble_progress" : ""}`}>
									{socialObj?.length !== 0 && (
										<PieGraph
											name={"Social"}
											DimData={socialObj}
											skillData={holisticSkillData[0]}
										/>
									)}
									<div className='NHProgresslistitem NhHolisticProgress allitemsNH'>
										<div className={`NHPreogressouter`}>
											<div
												className={`NHProgressinner ${showRoundValue(dashboardData?.progress) > 80
													? "flextwo"
													: ""
													}`}
												style={{
													width: `calc(${showRoundValue(
														dashboardData?.progress
													)}% + 12.5px)`,
													height: `calc(${showRoundValue(
														dashboardData?.progress
													)}% + 12.5px)`,
												}}>
												<p
													style={{
														bottom:
															showRoundValue(dashboardData?.progress) / 2 +
															50 +
															"%",
													}}>
													{" "}
													{/* need to do some change in css */}
													{showRoundValue(dashboardData?.progress)}
													<span className='percentage_sign'>%</span>
												</p>
												{/* {progressCount >= 30 && (
                        <h3>{getProfileName(defaultChildData)}</h3>
                      )} */}
											</div>
										</div>
										<div className='NHDimeProgName flextwo mt-2'>
											<h4>
												<img src='' alt='' />
												{getCapitalized(defaultChildData.firstName)}'s Overall
												Progress
											</h4>{" "}
											<span
												className='pointer'
												onClick={() => handleShowOverallProgressPopup(true)}>
												<img src={image.chat_icon} alt='' className='chat_icon' />
											</span>
										</div>
									</div>
									{emotionalObj?.length !== 0 && (
										<PieGraph
											name={"Emotional"}
											DimData={emotionalObj}
											skillData={holisticSkillData[3]}
										/>
									)}
									{intellectualObj?.length !== 0 && (
										<PieGraph
											name={"Intellectual"}
											DimData={intellectualObj}
											skillData={holisticSkillData[2]}
										/>
									)}
									{spiritualObj?.length !== 0 && (
										<PieGraph
											name={"Mindfulness"}
											DimData={spiritualObj}
											skillData={holisticSkillData[4]}
										/>
									)}

									{physicalObj?.length !== 0 && (
										<PieGraph
											name={"Physical"}
											DimData={physicalObj}
											skillData={holisticSkillData[1]}
										/>

									)}
								</div>
							</div>
						) : (
							<div className='NhPageWrap'>
								<div className='NHProgreschartList'>
									<ShimmerSimpleGallery
										imageType='circular'
										imageHeight={200}
										caption
									/>
								</div>
							</div>
						)}
					</div>
					<div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip ">
						<div class="form-group BDsubmitbutton d-flex justify-content-right align-items-center m-0 ">
							<div className="w-100">
								{/* <button class="btn-blue btn-login d-block float-right w-auto"
														>Next
														<span>
															<i className="fa-solid fa-arrow-right m-0 ml-2"></i>
														</span>
													</button> */}
							</div>

						</div>
					</div>
				</div>
			)}
			{location?.showRightPanel === "Popular Courses" ? (
				<>
					<Sidebar
						data={dashboardData}
						handleToggleState={handleToggleState}
						toggleState={toggleState}
					/>

				</>
			) : (
				(loggedInUser?.role?.name !== "STUDENT" || showPanelObj?.type !== "showGrowthSurveyPanel") && (
					<div className='RightbarPannel p-0 rightpannelSticky newcoursecardpanel home_page_rgt_pnl'>
						<LearnerPlanRightPanel
							screen={1}
							selectedSitting={2}
							selectedMinutes={30}
							handleMoreOptions={handleMoreOptions}
							// selectedOption={location?.showRightPanel === "Rcommnded Plan" ? "recommended" : "home"}
							// 	getMyEditPlan={location?.showRightPanel === "Rcommnded Plan" ? "New Plan" : ""}
							selectedOption="home"
							getMyEditPlan=""
							handlePopUpOpen={handlePopUpOpen}
							coursePlanTree={[]}
							timezoneValue={"US/Eastern"}
							gotoSelectedPlan={gotoSelectedPlan}
							showPlanCourseData={showPlanCourseData}
							getDataFromCourseProgress={getDataFromCourseProgress}
							selctedDays={[]}
							getFlexibLeDays={[]}
							getValues={getValues}
							setValue={setValue}
							setSelectedTab={setSelectedTab}
						/>
					</div>
				)
			)}

			{/* {dashboardData ? (
				<Sidebar data={dashboardData} />
			) : (
				<div className="RightbarPannel p-0 rightpannelSticky">
					<ShimmerCategoryItem items={4} categoryStyle="STYLE_SIX" />
				</div>
			)} */}

			{showOverallProgressPopup && (
				<ShowOverallProgressPopup
					handleShowOverallProgressPopup={handleShowOverallProgressPopup}
				/>
			)}

			{showCongratulationModel && !courseDetail?.isAcademic && (
				<CongratulationCard
					courseActivityId={courseActivityId}
					handleOpenpopup={getDataFromCourseProgress}
					isContinueButtonClick={false}
				/>
			)}

			{congratulationPopup && courseDetail?.isAcademic && (
				<LessonCongratulationsPopup
					handleCongratulationPopup={handleCongratulationPopup}
					courseActivityId={courseActivityId}
				/>
			)}
		</>
	);
};

export default HolisticView;
