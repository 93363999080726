
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import * as image from "../../resources/images";
import { getCapitalized } from "../../utils/helper";
import { useReducer } from "react";
const ChallengesPopup = ({ showChallenge, setShowChallenge }) => {

  const popupRef = useRef(null);

  return (
    <div ref={popupRef}
      className="newinfo_popup newinfo_popupdimension  pacechangepopuo challenge_popup" >
      <div
        class="modal d-flex downgradepopup sectionscorecard"
        role="dialog"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header p-0 border-0">
              <div class="heading p-0 border-0 w-100">
                <h2 class="flex">
                  <button class="btn btn-primary" data-dismiss="modal" onClick={() => setShowChallenge()}> <i className="fa-regular fa-xmark m-0"></i> </button></h2>
              </div>
            </div>
            <div class="modal-body p-0">
              <div class={`intelligenceTyoedetail intelligenceTyoedetail${showChallenge?.key} flex`}>
                <div class="challenge_img">
                  <img
                    src={`https://bloomster.com/hubfs/HMF%20Plan/Mobile%20Application/why_this_${showChallenge?.key + 1}.jpg`}
                    alt="Musical"
                  />
                </div>
                <div class="pl-3">
                  <h3 class="mb-3">{showChallenge?.challenge}</h3>
                  <h4>How This Plan Helps</h4>
                  <p>{showChallenge?.howthishelps}</p>
                  <p>
                    {showChallenge?.how_this_helps}
                  </p>
                  <div class="scoreDescription">
                    <div class="">
                      <ul>
                        <li>
                          {" "}
                          <strong> Skill: </strong> {showChallenge?.skill}
                        </li>
                        <li>
                          <strong>Course: </strong>
                          {showChallenge?.course}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChallengesPopup;